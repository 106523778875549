import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {
  withStyles,
  withTheme,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";

import HomeIcon from "@material-ui/icons/Home";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
// import CompareIcon from "@material-ui/icons/Compare";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import AddCommentOutlinedIcon from "@material-ui/icons/AddCommentOutlined";

import { Link } from "react-router-dom";
import "whatwg-fetch"; //For IE11
import "promise-polyfill/src/polyfill"; //For IE11
// import ProductList from "../data/productList.json";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: "#E44A4B",
    },
    secondary: {
      main: "#FFFFFF", //"#f44336"
    },
  },
});

const styles = (theme) => ({
  card: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    maxWidth: 225,
    [theme.breakpoints.down(960)]: {
      width: "100%",
      maxWidth: "100%",
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
    },
    [theme.breakpoints.down(600)]: {
      width: "75%",
      maxWidth: "75%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
    },
  },
  media: {
    height: 150,
    backgroundSize: "contain",
    [theme.breakpoints.down(960)]: {
      height: 200,
      backgroundSize: "contain",
    },
    [theme.breakpoints.down(600)]: {
      height: 250,
      backgroundSize: "contain",
    },
    transform: "scale(1, 1)",
    transition: "transform 300ms ease-out",
    "&:hover": {
      transform: "scale(1.1, 1.1)",
    },
  },
  cardAction: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    paddingTop: 0,
    height: 25,
  },
  cardContent: {
    minHeight: 130,
  },
  compareIcon: {
    marginLeft: "auto",
  },
  fab: {
    position: "absolute",
    left: theme.spacing.unit / 2,
    top: 0,
    backgroundColor: "#E44A4B",
    color: "#FFFFFF",
    padding: `${theme.spacing.unit / 2}px ${theme.spacing.unit * 2}px`,
  },
  rightIcon: {
    marginRight: theme.spacing.unit,
  },
  paper: {
    background: "#EEEEEE",
    [theme.breakpoints.down(600)]: {
      marginLeft: "25%",
      width: "100%",
    },
  },
});

class ProductCard extends React.Component {
  state = {
    productList: {},
    isLoadedList: false,
  };

  componentWillMount() {
    var http = "https://";
    if (
      !process.env.NODE_ENV ||
      process.env.NODE_ENV === "development" ||
      window.location.protocol !== "https:"
    ) {
      http = "http://";
    }
    window
      .fetch(http + window.location.host + "/data/productList.json")
      .then((r) => r.json())
      .then((data) => {
        this.setState({ productList: data });
        this.setState({ isLoadedList: true });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    if (!this.state.isLoadedList) {
      return null;
    }

    const { classes } = this.props;
    const productList = this.state.productList;
    var products = [];
    var productType = this.props.match.params.productType;
    var isNew =
      productType === "new" || !productList.hasOwnProperty(productType);
    if (isNew) {
      productType = "new";
      for (var key in productList) {
        var listLength = productList[key].length;
        for (var i = 0; i < listLength; i++) {
          if (productList[key][i].isNew) {
            products.push(JSON.parse(JSON.stringify(productList[key][i])));
            products[products.length - 1].link =
              "/product/" + key + products[products.length - 1].link;
          }
        }
      }
    } else {
      products = productList[productType];
    }
    return (
      <MuiThemeProvider theme={theme}>
        <Helmet>
          <title>
            {productType.toUpperCase() + ": " + products[0].subtitle} -
            LinkChamp Products
          </title>
          <meta
            name="description"
            content={
              productType.toUpperCase() +
              ": " +
              products[0].subtitle +
              ", " +
              products[0].description +
              " designed and manufactured by LinkChamp"
            }
          />
          <meta
            name="keywords"
            content={products[0].subtitle + ", " + products[0].description}
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={"http://" + window.location.host + this.props.match.url}
          />
          <meta
            property="og:title"
            content={productType.toUpperCase() + " - LinkChamp Product Series"}
          />
          <meta
            property="og:description"
            content={
              productType.toUpperCase() +
              ": " +
              products[0].subtitle +
              ", " +
              products[0].description +
              " designed and manufactured by LinkChamp"
            }
          />
          <meta
            property="og:image"
            content={"http://" + window.location.host + products[0].image}
          />
        </Helmet>
        <Grid container direction="row" justify="flex-start" spacing={32}>
          <Grid item>
            <Paper className={classes.paper} elevation={0}>
              <Button className={classes.button}>
                <HomeIcon className={classes.rightIcon} />
                Product
                <NavigateNextIcon className={classes.rightIcon} />
                <Typography variant="subtitle2" component="h1">
                  {productType}
                </Typography>
              </Button>
            </Paper>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="flex-start" spacing={40}>
          {products.map((product) =>
            product.isLegacy != null && product.isLegacy === true ? (
              <React.Fragment key={product.title}></React.Fragment>
            ) : (
              <Grid item key={product.title} xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardActionArea
                    component={Link}
                    to={
                      isNew
                        ? `${product.link}`
                        : `${this.props.match.url}${product.link}`
                    }
                  >
                    <CardMedia
                      className={classes.media}
                      image={product.image}
                      title={product.title}
                    />
                    {product.isNew && (
                      <Paper className={classes.fab}>
                        <Typography
                          variant="subtitle2"
                          component="h2"
                          color="secondary"
                        >
                          NEW
                        </Typography>
                      </Paper>
                    )}
                    <CardContent className={classes.cardContent}>
                      <Typography variant="subtitle2" component="h2">
                        {product.title}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="h2"
                        color="textSecondary"
                      >
                        {product.subtitle}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {product.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions className={classes.cardAction}>
                    <Button
                      size="small"
                      color="primary"
                      component={Link}
                      to={
                        isNew
                          ? `${product.link}`
                          : `${this.props.match.url}${product.link}`
                      }
                      button="true"
                    >
                      Details
                    </Button>
                    {/* <Tooltip
                    title="Compare (Coming Soon)"
                    aria-label="Add to Compare"
                  >
                    <IconButton
                      aria-label="Add to Compare"
                      className={classes.compareIcon}
                    >
                      <CompareIcon />
                    </IconButton>
                  </Tooltip> */}
                    <Tooltip
                      title={
                        this.props.cart[product.title] !== undefined
                          ? "Remove from Inquiry"
                          : "Add to Inquiry"
                      }
                      aria-label="Add to Inquiry"
                    >
                      <Button
                        size="small"
                        color="primary"
                        onClick={() => {
                          if (this.props.cart[product.title] !== undefined) {
                            this.props.cartCallback({
                              name: product.title,
                              moq: 0,
                            });
                          } else {
                            this.props.cartCallback({
                              img: product.image,
                              name: product.title,
                              moq: 100,
                              url: isNew
                                ? `${product.link}`
                                : `${this.props.match.url}${product.link}`,
                              spec:
                                product.subtitle.includes("Converter") ||
                                product.subtitle.includes("Controller")
                                  ? 0
                                  : 230,
                              isNewAdd: true,
                            });
                          }
                        }}
                        button="true"
                        className={classes.compareIcon}
                      >
                        {this.props.cart[product.title] !== undefined ? (
                          <CheckBoxIcon />
                        ) : (
                          <AddCommentOutlinedIcon />
                        )}
                      </Button>
                    </Tooltip>
                  </CardActions>
                </Card>
              </Grid>
            )
          )}
        </Grid>
      </MuiThemeProvider>
    );
  }
}

ProductCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(theme)(withStyles(styles)(ProductCard));
