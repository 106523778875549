import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import classNames from "classnames";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import Fab from "@material-ui/core/Fab";
import ListIcon from "@material-ui/icons/List";
import Drawer from "@material-ui/core/Drawer";

import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import ProductCard from "./productCard";
import ProductSpec from "./productSpec";
import ScrollToTop from "./scrollToTop";

import {
  withStyles,
  withTheme,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect,
} from "react-router-dom";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: "#2196f3",
    },
    secondary: {
      main: "#E44A4B",
    },
  },
});

const styles = (theme) => ({
  layout: {
    width: "auto",
    marginTop: theme.spacing.unit * 12,
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: "80%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: theme.spacing.unit * 12,
    },
  },
  divider: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  root: {
    width: "100%",
    maxWidth: 225,
    // backgroundColor: theme.palette.background.paper
  },
  list: {
    paddingLeft: theme.spacing.unit * 5,
  },
  nested: {
    paddingLeft: theme.spacing.unit * 7,
    paddingTop: theme.spacing.unit / 2,
    paddingBottom: theme.spacing.unit / 2,
  },
  fab: {
    [theme.breakpoints.down(960)]: {
      position: "fixed",
      top: theme.spacing.unit * 10,
      left: theme.spacing.unit * 1.5,
      zIndex: 1,
    },
  },
  gridRoute: {
    minHeight: 1200,
  },
});

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expandPSW: true,
      expandMSW: true,
      leftDrawer: false,
      expandLegacy: false,
    };
  }

  toggleState = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };

  render() {
    const { classes } = this.props;
    const sideList = (
      <Grid item md={3}>
        {/* //TODO this is nestedList but i can't find a way to make router work when it's a separated component*/}
        <List component="nav" className={classes.root} dense={true}>
          <ListSubheader disableSticky component="div">
            New Products
          </ListSubheader>
          <ListItem
            button
            className={classes.list}
            component={Link}
            to="/product/new"
            onClick={this.toggleState("leftDrawer", false)}
          >
            <ListItemText primary="New Products" />
          </ListItem>
          <Divider />
          <ListSubheader disableSticky component="div">
            DC-AC Inverter
          </ListSubheader>
          <ListItem
            button
            onClick={this.toggleState("expandPSW", !this.state.expandPSW)}
            className={classes.list}
          >
            <ListItemText primary="Pure Sine Wave" />
            {this.state.expandPSW ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state.expandPSW} timeout="auto" unmountOnExit>
            <List component="div" dense={true}>
              <ListItem
                button
                className={classes.nested}
                component={Link}
                to="/product/sn-bt-series"
                onClick={this.toggleState("leftDrawer", false)}
              >
                <ListItemText
                  primary="SN-BT Series"
                  secondary="Smartphone Control"
                />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                component={Link}
                to="/product/sn-series"
                onClick={this.toggleState("leftDrawer", false)}
              >
                <ListItemText primary="SN Series" secondary="Classic & LCD" />
              </ListItem>
            </List>
          </Collapse>
          <ListItem
            button
            onClick={this.toggleState("expandMSW", !this.state.expandMSW)}
            className={classes.list}
          >
            <ListItemText primary="Mod Sine Wave" />
            {this.state.expandMSW ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state.expandMSW} timeout="auto" unmountOnExit>
            <List component="div" dense={true}>
              <ListItem
                button
                className={classes.nested}
                component={Link}
                to="/product/hp-series"
                onClick={this.toggleState("leftDrawer", false)}
              >
                <ListItemText primary="HP Series" secondary="Compact" />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                component={Link}
                to="/product/sp-series"
                onClick={this.toggleState("leftDrawer", false)}
              >
                <ListItemText primary="SP Series" secondary="Classic" />
              </ListItem>
            </List>
          </Collapse>
          <Divider />
          <ListSubheader disableSticky component="div">
            DC-AC Inverter Charger
          </ListSubheader>
          <ListItem
            button
            className={classes.list}
            component={Link}
            to="/product/sn-c-series"
            onClick={this.toggleState("leftDrawer", false)}
          >
            <ListItemText primary="Pure Sine Wave" secondary="SN-C Series" />
          </ListItem>
          <ListItem
            button
            className={classes.list}
            component={Link}
            to="/product/hp-c-series"
            onClick={this.toggleState("leftDrawer", false)}
          >
            <ListItemText primary="Mod Sine Wave" secondary="HP-C Compact" />
          </ListItem>
          <ListItem
            button
            className={classes.list}
            component={Link}
            to="/product/sp-c-series"
            onClick={this.toggleState("leftDrawer", false)}
          >
            <ListItemText primary="Mod Sine Wave" secondary="SP-C Classic" />
          </ListItem>
          <Divider />
          <ListSubheader disableSticky component="div">
            DC-DC Converter
          </ListSubheader>
          <ListItem
            button
            className={classes.list}
            component={Link}
            to="/product/dc-1224-series"
            onClick={this.toggleState("leftDrawer", false)}
          >
            <ListItemText primary="DC 12V to 24V" secondary="Boost (Step-Up)" />
          </ListItem>
          <ListItem
            button
            className={classes.list}
            component={Link}
            to="/product/dc-2412-series"
            onClick={this.toggleState("leftDrawer", false)}
          >
            <ListItemText
              primary="DC 24V to 12V"
              secondary="Buck (Step-Down)"
            />
          </ListItem>
          <ListItem
            button
            className={classes.list}
            component={Link}
            to="/product/dc-48-series"
            onClick={this.toggleState("leftDrawer", false)}
          >
            <ListItemText primary="DC 48V" secondary="Buck (Step-Down)" />
          </ListItem>
          <Divider />
          <ListSubheader disableSticky component="div">
            Battery Charger
          </ListSubheader>
          <ListItem
            button
            className={classes.list}
            component={Link}
            to="/product/ad-series"
            onClick={this.toggleState("leftDrawer", false)}
          >
            <ListItemText primary="AD Series" secondary="12/24/48V" />
          </ListItem>
          <Divider />
          {/* <ListSubheader disableSticky component="div">
            Solar
          </ListSubheader>
          <ListItem
            button
            className={classes.list}
            component={Link}
            to="/product/sc-series"
            onClick={this.toggleState("leftDrawer", false)}
          >
            <ListItemText primary="Solar Charge Controller" />
          </ListItem>
          <ListItem
            button
            className={classes.list}
            component={Link}
            to="/product/ls-series"
            onClick={this.toggleState("leftDrawer", false)}
          >
            <ListItemText primary="Solar Inverter" />
          </ListItem>
          <Divider /> */}
          <ListSubheader disableSticky component="div">
            Accessories
          </ListSubheader>
          <ListItem
            button
            className={classes.list}
            component={Link}
            to="/product/rc-series"
            onClick={this.toggleState("leftDrawer", false)}
          >
            <ListItemText primary="Remote Control" />
          </ListItem>
          <Divider />
          <ListSubheader disableSticky component="div">
            Others
          </ListSubheader>
          <ListItem
            button
            onClick={this.toggleState("expandLegacy", !this.state.expandLegacy)}
            className={classes.list}
          >
            <ListItemText primary="Specialized" />
            {this.state.expandLegacy ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state.expandLegacy} timeout="auto" unmountOnExit>
            <List component="div" dense={true}>
              <ListItem
                button
                className={classes.list}
                component={Link}
                to="/product/es-series"
                onClick={this.toggleState("leftDrawer", false)}
              >
                <ListItemText
                  primary="ES Series"
                  secondary="Energy Saving Inverter"
                />
              </ListItem>
              <ListItem
                button
                className={classes.list}
                component={Link}
                to="/product/1224-series"
                onClick={this.toggleState("leftDrawer", false)}
              >
                <ListItemText
                  primary="12V/24V Series"
                  secondary="Dual Input Types Inverter"
                />
              </ListItem>
              <ListItem
                button
                className={classes.list}
                component={Link}
                to="/product/ats-series"
                onClick={this.toggleState("leftDrawer", false)}
              >
                <ListItemText
                  primary="ATS Series"
                  secondary="Auto AC Transfer Switch"
                />
              </ListItem>
              <ListItem
                button
                className={classes.list}
                component={Link}
                to="/product/dc-4in1-series"
                onClick={this.toggleState("leftDrawer", false)}
              >
                <ListItemText
                  primary="DC 4-IN-1"
                  secondary="Buck-Boost 10~60V"
                />
              </ListItem>
              <ListItem
                button
                className={classes.list}
                component={Link}
                to="/product/ls-series"
                onClick={this.toggleState("leftDrawer", false)}
              >
                <ListItemText
                  primary="Solar Inverter"
                  secondary="PWM 300~5000W"
                />
              </ListItem>
              <ListItem
                button
                className={classes.list}
                component={Link}
                to="/product/sc-series"
                onClick={this.toggleState("leftDrawer", false)}
              >
                <ListItemText
                  primary="Solar Charge Controller"
                  secondary="PWM 15~50A"
                />
              </ListItem>
            </List>
          </Collapse>
        </List>
      </Grid>
    );

    return (
      <Router>
        <ScrollToTop>
          <MuiThemeProvider theme={theme}>
            <React.Fragment>
              <CssBaseline />
              <main>
                <Helmet>
                  <title>LinkChamp - Product</title>
                  <meta
                    name="description"
                    content="Power Inverter, Pure Sine Wave Inverter, Inverter Charger, Converter, and Battery Charger that designed and manufactured in Taiwan"
                  />
                </Helmet>
                <div className={classNames(classes.layout)}>
                  <Drawer
                    open={this.state.leftDrawer}
                    onClose={this.toggleState("leftDrawer", false)}
                  >
                    <div
                      tabIndex={0}
                      role="button"
                      // onClick={this.toggleDrawer("leftDrawer", false)}
                      // onKeyDown={this.toggleDrawer("leftDrawer", false)}
                    >
                      {sideList}
                    </div>
                  </Drawer>
                  <Grid item xs={12}>
                    <Hidden mdUp>
                      <Fab
                        className={classes.fab}
                        color="default"
                        size="medium"
                        onClick={this.toggleState("leftDrawer", true)}
                      >
                        <ListIcon />
                      </Fab>
                    </Hidden>
                    <Typography
                      component="h3"
                      variant="h5"
                      gutterBottom
                      color="textSecondary"
                      align="center"
                    >
                      PRODUCT
                    </Typography>
                  </Grid>
                  <Divider variant="middle" className={classes.divider} />
                  <Grid container spacing={40}>
                    <Hidden smDown>{sideList}</Hidden>
                    <Hidden mdUp>
                      <Grid item sm={1} />
                    </Hidden>
                    <Grid item sm={11} md={9} className={classes.gridRoute}>
                      <Switch>
                        <Route
                          path="/product/:productType/:productNum"
                          render={(props) => (
                            <ProductSpec
                              {...props}
                              cartCallback={this.props.cartCallback}
                              cart={this.props.cart}
                            />
                          )}
                        />
                        <Route
                          path="/product/:productType"
                          render={(props) => (
                            <ProductCard
                              {...props}
                              cartCallback={this.props.cartCallback}
                              cart={this.props.cart}
                            />
                          )}
                        />
                        <Redirect
                          from="/"
                          to="/product/new"
                          render={(props) => (
                            <ProductCard
                              {...props}
                              cartCallback={this.props.cartCallback}
                              cart={this.props.cart}
                            />
                          )}
                        />
                      </Switch>
                    </Grid>
                  </Grid>
                </div>
              </main>
            </React.Fragment>
          </MuiThemeProvider>
        </ScrollToTop>
      </Router>
    );
  }
}

Product.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(theme)(withStyles(styles)(Product));
