import React from "react";
import ReactDOM from "react-dom";
import LCAppBar from "./pages/appBar";
import Footer from "./pages/footer";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<LCAppBar />, document.getElementById("navbar"));
ReactDOM.render(<Footer />, document.getElementById("footer"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
