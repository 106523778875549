import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Slide from "@material-ui/core/Slide";

const styles = theme => ({
  paper: {
    width: "60%",
    marginTop: theme.spacing.unit * 5,
    [theme.breakpoints.down(1280)]: {
      width: "85%",
      marginTop: theme.spacing.unit * 10
    },
    [theme.breakpoints.down(600)]: {
      width: "90%",
      marginTop: theme.spacing.unit * 15
    },
    marginLeft: "auto",
    marginRight: "auto",
    // height: "50%",
    // marginTop: "auto",
    // marginBottom: "auto",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: "none"
  },
  media: {
    height: 300,
    backgroundSize: "contain",
    [theme.breakpoints.down(960)]: {
      height: 250,
      backgroundSize: "contain"
    },
    [theme.breakpoints.down(600)]: {
      height: 200,
      backgroundSize: "contain"
    }
  },
  fab: {
    position: "absolute",
    backgroundColor: "#E44A4B",
    color: "#FFFFFF",
    padding: `${theme.spacing.unit / 2}px ${theme.spacing.unit * 2}px`
  }
});

class Lightbox extends React.Component {
  state = {
    open: false
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    const product = this.props.product;
    return (
      <div>
        <CardActionArea onClick={this.handleOpen}>
          <CardMedia
            className={classes.media}
            image={product.image}
            title={product.title}
          >
            {product.isNew && (
              <Paper className={classes.fab}>
                <Typography
                  variant="subtitle2"
                  component="h3"
                  color="secondary"
                >
                  NEW
                </Typography>
              </Paper>
            )}
          </CardMedia>
        </CardActionArea>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Slide
            direction="left"
            in={this.state.open}
            mountOnEnter
            unmountOnExit
          >
            <div className={classes.paper}>
              <img
                src={product.image}
                style={{ width: "100%", height: "100%" }}
                alt={product.title}
                title={product.title}
                loading="lazy"
              />
            </div>
          </Slide>
        </Modal>
      </div>
    );
  }
}

Lightbox.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Lightbox);
