import React from "react";
import { Helmet } from "react-helmet";
import { Link as RouterLink } from "react-router-dom";

import Link from "@material-ui/core/Link";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import SendIcon from "@material-ui/icons/Send";
import DoneIcon from "@material-ui/icons/Done";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteSharpIcon from "@material-ui/icons/DeleteSharp";

import {
  withStyles,
  withTheme,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: "16px 10px 8px 10px",
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: "#2196f3",
    },
    secondary: {
      main: "#E44A4B",
    },
  },
});

const styles = (theme) => ({
  root: {
    marginLeft: "5%",
    marginRight: "5%",
    marginTop: theme.spacing.unit * 12,
    [theme.breakpoints.up(960)]: {
      width: "60%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: theme.spacing.unit * 12,
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3,
    },
    overflowX: "auto",
  },
  formArea: {
    marginBottom: theme.spacing.unit * 1,
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  textFields: {
    padding: 0,
  },
});

class Inquiry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      company: "",
      website: "",
      name: "",
      email: "",
      tel: "",
      jobTitle: "",
      country: "",
      address: "",
      how: "",
      inquiry: "",
      message: "",
      location: "",
      isSubmitting: false,
      hasSent: false,
      hasError: false,
      isDialogOpen: false,
    };
  }

  componentDidMount() {
    //NOTE: using https://ipinfo.io/account free plan to get ip & location info
    fetch("https://ipinfo.io?token=20bedc24fd9975")
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        let locationInfo =
          result.ip + " " + result.city + ", " + result.country;
        // console.log("locationInfo:" + locationInfo);
        this.setState({
          location: locationInfo,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    // console.log("Json:" + JSON.stringify(this.state));
  };

  handleSubmit = (event) => {
    this.setState({ isSubmitting: true });
    event.preventDefault();
    const url =
      "https://script.google.com/a/dc-ac.com/macros/s/AKfycbzU7_yEANjzaj5gSTltbAtWoDwETxTmQj1JW3654A/exec";
    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        company: this.state.company,
        website: this.state.website,
        name: this.state.name,
        email: this.state.email,
        tel: this.state.tel,
        jobTitle: this.state.jobTitle,
        country: this.state.country,
        address: this.state.address,
        how: this.state.how,
        inquiry: JSON.stringify(this.props.cart),
        message: this.state.message,
        location: this.state.location,
      }),
      headers: {
        //NOTE: this has to be "text/plain", otherwise "blocked by CORS policy" will show up
        "Content-Type": "text/plain",
      },
    })
      .then((res) => console.log("Result:", res))
      .catch((error) => {
        console.error("Error:", error);
        this.setState({ isSubmitting: false, hasSent: true, hasError: true });
      })
      .then((response) => {
        // console.log("Success:", response);
        this.setState({ isSubmitting: false, hasSent: true, hasError: false });
      });
  };

  handleDialogOpen = () => {
    this.setState({ isDialogOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ isDialogOpen: false });
  };

  handleDialogConfirm = () => {
    this.setState({ isDialogOpen: false });
    localStorage.clear();
    window.location.reload();
  };

  render() {
    const { classes } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <React.Fragment>
          <CssBaseline />
          <div className={classes.root}>
            <Helmet>
              <title>LinkChamp - Inquiry</title>
              <meta
                name="description"
                content="Contact us to get the latest quotation on products including Inverter, Converter, Battery Charger, and Solar Charge Controller."
              />
            </Helmet>
            <Typography
              component="h1"
              variant="h5"
              gutterBottom
              color="textSecondary"
              align="center"
            >
              INQUIRY
            </Typography>
            {this.state.isSubmitting || this.state.hasSent ? (
              <>
                <Paper className={classes.paper}>
                  <Grid
                    container
                    spacing={16}
                    direction="column"
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle2"
                        component="h2"
                        gutterBottom
                      >
                        {this.state.isSubmitting
                          ? "Submitting inquiry..."
                          : this.state.hasError
                          ? "Submission failed, please try again later."
                          : "Thank you for submitting the inquiry!"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {this.state.isSubmitting ? (
                        <CircularProgress color="secondary" />
                      ) : (
                        <Fab
                          color="secondary"
                          aria-label="Done"
                          onClick={() => {
                            if (!this.state.hasError) {
                              localStorage.clear();
                            }
                            window.location.reload();
                          }}
                        >
                          <DoneIcon />
                        </Fab>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </>
            ) : (
              <>
                <form onSubmit={this.handleSubmit}>
                  <Paper className={classes.paper}>
                    <div>
                      <Typography
                        variant="subtitle1"
                        component="h3"
                        gutterBottom
                      >
                        <b>Inquiry Cart:</b>
                      </Typography>
                    </div>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      className={classes.formArea}
                    >
                      <Grid item xs={12}>
                        {Object.keys(this.props.cart).length > 0 ? (
                          <Table className={classes.table}>
                            <TableHead>
                              <TableRow>
                                <TableCell align="center">Product</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell align="left">AC Voltage</TableCell>
                                <TableCell align="left">MOQ</TableCell>
                                <TableCell align="center"> </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Object.keys(this.props.cart).map(
                                (key, index) => (
                                  <TableRow key={index}>
                                    <TableCell align="center">
                                      <Link
                                        component={RouterLink}
                                        to={this.props.cart[key].url}
                                      >
                                        <img
                                          src={this.props.cart[key].img}
                                          alt={key}
                                          loading="lazy"
                                          width={64}
                                        />
                                      </Link>
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      <Link
                                        component={RouterLink}
                                        to={this.props.cart[key].url}
                                      >
                                        <Typography
                                          variant="button"
                                          gutterBottom
                                        >
                                          {key}
                                        </Typography>
                                      </Link>
                                    </TableCell>
                                    <TableCell align="left">
                                      <FormControl>
                                        <Select
                                          value={this.props.cart[key].spec}
                                          onChange={(event) => {
                                            this.props.cartCallback({
                                              name: key,
                                              moq: this.props.cart[key].moq,
                                              img: this.props.cart[key].img,
                                              url: this.props.cart[key].url,
                                              spec: event.target.value,
                                              isNewAdd: false,
                                            });
                                          }}
                                        >
                                          <MenuItem value={0}>N/A</MenuItem>
                                          <MenuItem value={230}>
                                            220~240V
                                          </MenuItem>
                                          <MenuItem value={120}>
                                            110~127V
                                          </MenuItem>
                                          <MenuItem value={100}>100V</MenuItem>
                                        </Select>
                                      </FormControl>
                                    </TableCell>
                                    <TableCell align="left">
                                      <FormControl>
                                        <Select
                                          value={this.props.cart[key].moq}
                                          onChange={(event) => {
                                            this.props.cartCallback({
                                              name: key,
                                              moq: event.target.value,
                                              img: this.props.cart[key].img,
                                              url: this.props.cart[key].url,
                                              spec: this.props.cart[key].spec,
                                              isNewAdd: false,
                                            });
                                          }}
                                        >
                                          <MenuItem value={10}>10</MenuItem>
                                          <MenuItem value={50}>50</MenuItem>
                                          <MenuItem value={100}>100</MenuItem>
                                          <MenuItem value={200}>200</MenuItem>
                                          <MenuItem value={500}>500</MenuItem>
                                        </Select>
                                      </FormControl>
                                    </TableCell>
                                    <TableCell align="right">
                                      <IconButton
                                        aria-label="Delete"
                                        onClick={() => {
                                          this.props.cartCallback({
                                            name: key,
                                            moq: 0,
                                          });
                                        }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        ) : (
                          <TextField
                            disabled
                            error
                            label="Your Inquiry Cart is Empty"
                            fullWidth
                            multiline
                            rows="3"
                            className={classes.textField}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                  <Paper className={classes.paper}>
                    <div>
                      <Typography
                        variant="subtitle1"
                        component="h2"
                        gutterBottom
                        inline
                      >
                        <b>Contact Info:</b>
                      </Typography>
                      {/* <Typography
                        variant="subtitle2"
                        gutterBottom
                        color="secondary"
                        inline
                      >
                        * Required fields
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom inline>
                        <b>)</b>
                      </Typography> */}
                    </div>

                    <Grid container className={classes.formArea}>
                      <Grid item xs={12} sm={6} className={classes.textFields}>
                        <TextField
                          required
                          margin="dense"
                          id="company"
                          name="company"
                          label="Company"
                          autoComplete="organization"
                          onChange={this.handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} className={classes.textFields}>
                        <TextField
                          required
                          margin="dense"
                          id="website"
                          name="website"
                          label="Website"
                          autoComplete="url"
                          onChange={this.handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          margin="dense"
                          id="name"
                          name="name"
                          label="Name"
                          autoComplete="given-name"
                          onChange={this.handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          margin="dense"
                          id="email"
                          name="email"
                          label="Email"
                          autoComplete="email"
                          onChange={this.handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          margin="dense"
                          id="tel"
                          name="tel"
                          label="Tel"
                          autoComplete="tel"
                          onChange={this.handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} className={classes.textFields}>
                        <TextField
                          required
                          margin="dense"
                          id="jobTitle"
                          name="jobTitle"
                          label="Job Title"
                          autoComplete="organization-title"
                          onChange={this.handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          margin="dense"
                          id="country"
                          name="country"
                          label="Country"
                          autoComplete="billing country"
                          onChange={this.handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          margin="dense"
                          id="address"
                          name="address"
                          label="Address"
                          fullWidth
                          autoComplete="billing address-line1"
                          onChange={this.handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          margin="dense"
                          id="how"
                          name="how"
                          label="How did you find LinkChamp?"
                          fullWidth
                          onChange={this.handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>

                    <div>
                      <Typography
                        variant="subtitle1"
                        component="h3"
                        gutterBottom
                      >
                        <b>Message:</b>
                      </Typography>
                    </div>
                    <Grid container spacing={16} className={classes.formArea}>
                      <Grid item xs={12}>
                        <TextField
                          margin="dense"
                          id="message"
                          name="message"
                          label="Anything else you would like to know?"
                          fullWidth
                          multiline
                          rows="5"
                          className={classes.textField}
                          variant="outlined"
                          onChange={this.handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>

                    <div className={classes.buttons}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        className={classes.button}
                        onClick={this.handleDialogOpen}
                      >
                        <DeleteSharpIcon className={classes.leftIcon} />
                        Discard
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        type="submit"
                        disabled={Object.keys(this.props.cart).length <= 0}
                      >
                        <SendIcon className={classes.leftIcon} />
                        Submit
                      </Button>
                    </div>
                  </Paper>
                </form>
                <Dialog
                  open={this.state.isDialogOpen}
                  onClose={this.handleDialogClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Discard the inquiry?"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Warning! All the items and contact info you filled will be
                      removed.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.handleDialogClose} color="default">
                      Return
                    </Button>
                    <Button
                      onClick={this.handleDialogConfirm}
                      color="secondary"
                      autoFocus
                    >
                      Discard
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </div>
        </React.Fragment>
      </MuiThemeProvider>
    );
  }
}

export default withTheme(theme)(withStyles(styles)(Inquiry));
