import React from "react";
import { Helmet } from "react-helmet";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import CardActionArea from "@material-ui/core/CardActionArea";
import Avatar from "@material-ui/core/Avatar";

import IconEmail from "@material-ui/icons/Email";
import IconPhone from "@material-ui/icons/Phone";
import IconPrint from "@material-ui/icons/Print";
import IconLinkedin from "../images/linkedin.png";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    width: "70%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing.unit * 12
  },
  paper: {
    marginLeft: "auto",
    marginRight: "auto",
    padding: theme.spacing.unit * 2,
    width: 160
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
    backgroundColor: "#ffffff",
    color: "#E44A4B"
  },
  icon: {
    width: 45,
    height: 45
  },
  actionArea: {
    color: "#E44A4B"
  }
});

function Contact(props) {
  const { classes } = props;
  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.root}>
        <Helmet>
          <title>LinkChamp - Contact</title>
          <meta
            name="description"
            content="Intelligent DC-AC® Power Inverter, Converter, Battery Charger, Solar Charge Controller OEM/ODM Manufacturer in Taiwan"
          />
        </Helmet>
        <Grid
          container
          spacing={16}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography
              component="h1"
              variant="h5"
              gutterBottom
              color="textSecondary"
              align="center"
            >
              CONTACT
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <iframe
                title="Google Map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d29386.113196715924!2d120.2188246!3d22.9773121!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x29e84f5a22780a20!2z5p6X5piM6Zu75a2Q5bel5qWt6IKh5Lu95pyJ6ZmQ5YWs5Y-4!5e0!3m2!1sen!2stw!4v1554715664569!5m2!1sen!2stw"
                width="100%"
                height={window.screen.availHeight / 2}
                frameBorder="0"
                //   style="border:0"
                allowFullScreen
              />
            </Paper>
          </Grid>
          {/* //TODO:check http://ir.tesla.com/ for their paper examples */}
          <Grid item xs={12} md={3}>
            <Paper className={classes.paper} align="center">
              <CardActionArea className={classes.actionArea}>
                <Avatar className={classes.bigAvatar}>
                  <IconEmail className={classes.icon} />
                </Avatar>
              </CardActionArea>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                sales@dc-ac.com
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={3}>
            <Paper className={classes.paper} align="center">
              <CardActionArea className={classes.actionArea}>
                <Avatar className={classes.bigAvatar}>
                  <IconPhone className={classes.icon} />
                </Avatar>
              </CardActionArea>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                +886-6-2796386
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={3}>
            <Paper className={classes.paper} align="center">
              <CardActionArea className={classes.actionArea}>
                <Avatar className={classes.bigAvatar}>
                  <IconPrint className={classes.icon} />
                </Avatar>
              </CardActionArea>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                +886-6-2796369
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={3}>
            <Paper className={classes.paper} align="center">
              <CardActionArea
                className={classes.actionArea}
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/company/linkchamp",
                    "_blank"
                  )
                }
              >
                <Avatar className={classes.bigAvatar}>
                  <img
                    src={IconLinkedin}
                    alt="Linkedin Page"
                    title="Linkedin Page"
                  />
                </Avatar>
              </CardActionArea>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                Linkedin Page
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}

export default withStyles(styles)(Contact);
