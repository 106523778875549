import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Hidden from "@material-ui/core/Hidden";
import Link from "@material-ui/core/Link";

import IconLocationOn from "@material-ui/icons/LocationOn";
import IconEmail from "@material-ui/icons/Email";
import IconPhone from "@material-ui/icons/Phone";
import IconPrint from "@material-ui/icons/Print";

const styles = (theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing.unit * 8,
    padding: `${theme.spacing.unit * 6}px 0`,
  },
  chip: {
    margin: theme.spacing.unit,
  },
});

function Footer(props) {
  const { classes } = props;
  return (
    <React.Fragment>
      <footer className={classes.footer}>
        <Link href="/" style={{ textDecoration: "none", color: "inherit" }}>
          <Typography
            variant="subtitle2"
            component="h2"
            align="center"
            color="textSecondary"
            gutterBottom
          >
            LINKCHAMP CO., LTD.
          </Typography>
        </Link>
        <Typography
          component="span"
          variant="body2"
          align="center"
          color="textSecondary"
        >
          <Hidden xsDown>
            <Chip
              variant="outlined"
              icon={<IconLocationOn />}
              label="No. 28, Lane 687, ZhongShan Rd., Rende Dist., Tainan City 71742, Taiwan"
              onClick={() =>
                window.open("https://goo.gl/maps/KsHqrGK1ofJ2", "_blank")
              }
              className={classes.chip}
            />
          </Hidden>
          <Hidden smUp>
            <Chip
              variant="outlined"
              icon={<IconLocationOn />}
              label={"No. 28, Lane 687, ZhongShan Rd., Tainan, Taiwan"}
              onClick={() =>
                window.open("https://goo.gl/maps/KsHqrGK1ofJ2", "_blank")
              }
              className={classes.chip}
            />
          </Hidden>
        </Typography>
        <Typography
          component="span"
          variant="body2"
          align="center"
          color="textSecondary"
        >
          <Chip
            variant="outlined"
            icon={<IconEmail />}
            label="sales@dc-ac.com"
            onClick={() => window.open("mailto:sales@dc-ac.com", "_blank")}
            className={classes.chip}
          />
          <Chip
            variant="outlined"
            icon={<IconPhone />}
            label="+886-6-2796386"
            onClick={() => window.open("tel:+886-6-2796386", "_blank")}
            className={classes.chip}
          />
          <Chip
            variant="outlined"
            icon={<IconPrint />}
            label="+886-6-2796369"
            onClick={() => window.open("tel:+886-6-2796369", "_blank")}
            className={classes.chip}
          />
        </Typography>
        <Typography
          variant="subtitle2"
          component="h3"
          align="center"
          color="textSecondary"
          gutterBottom
        >
          Copyright © {new Date().getFullYear()} LINKCHAMP. All Rights Reserved.
        </Typography>
        <Link
          href="/privacypolicy"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Typography
            variant="subtitle2"
            component="h3"
            align="center"
            color="textSecondary"
            gutterBottom
          >
            Privacy Policy
          </Typography>
        </Link>
      </footer>
    </React.Fragment>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
