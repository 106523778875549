import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import IconPowerOff from "@material-ui/icons/PowerOff";
import Grid from "@material-ui/core/Grid";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing.unit * 12,
    marginBottom: theme.spacing.unit * 20,
    [theme.breakpoints.up(960)]: {
      width: "80%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: theme.spacing.unit * 12,
      marginBottom: theme.spacing.unit * 20,
    },
  },
  card: {
    width: "60%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing.unit * 6,
    marginBottom: theme.spacing.unit * 20,
  },
  icon: {
    width: 128,
    height: 128,
    color: "#BCBCBB",
  },
  button: {
    margin: theme.spacing.unit,
  },
});

function NotFound(props) {
  const { classes } = props;
  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.root}>
        <Helmet>
          <title>
            {/* LinkChamp - Professional Power Solutions Design Manufacturing 404
            Error */}
            LinkChamp - Power Inverter, Pure Sine Inverter Charger Manufacturer
            404 Error
          </title>
          <meta
            name="description"
            content="404 Error - Page not found, it might be an old link or the
                  page has been moved."
          />
        </Helmet>
        <Typography
          component="h2"
          variant="h5"
          gutterBottom
          color="textSecondary"
          align="center"
        >
          <IconPowerOff className={classes.icon} />
        </Typography>
        <Card className={classes.card}>
          <CardContent>
            <Grid container spacing={8} justify="center" alignItems="center">
              <Grid xs={12}>
                <Typography
                  variant="h5"
                  component="h2"
                  color="textSecondary"
                  gutterBottom
                >
                  404 Error - Page not found!
                </Typography>
              </Grid>
              <Grid xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Sorry, we can't find that page. It might be an old link or the
                  page has been moved.
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
          <div style={{ textAlign: "right", margin: 20 }}>
            <Button
              variant="outlined"
              color="secondary"
              component={Link}
              to="/"
              className={classes.button}
            >
              Back to Homepage
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              component={Link}
              to="contact"
              className={classes.button}
            >
              Contact us
            </Button>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
}

NotFound.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NotFound);
