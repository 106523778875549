import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Hidden from "@material-ui/core/Hidden";
import Badge from "@material-ui/core/Badge";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import MenuIcon from "@material-ui/icons/Menu";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";

import logo from "../images/linkchamp_logo_64.png";
import Index from "./index";
import Company from "./company";
import Product from "./product";
import TradeShow from "./tradeShow";
import Inquiry from "./inquiry";
import Contact from "./contact";
import PrivacyPolicy from "./privacyPolicy";
import ScrollToTop from "./scrollToTop";
import NotFound from "./notFound";

import {
  withStyles,
  withTheme,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect,
} from "react-router-dom";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#E44A4B",
    },
  },
});

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  tab: {
    minWidth: 72,
  },
  drawerButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: "none",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: `0 ${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 2}px`,
    justifyContent: "flex-end",
  },
};

const pages = [
  {
    label: "COMPANY",
    link: "/company",
  },
  {
    label: "PRODUCT",
    link: "/product",
  },
  {
    label: "NEWS",
    link: "/news",
  },
  {
    label: "INQUIRY",
    link: "/inquiry",
  },
  {
    label: "CONTACT",
    link: "/contact",
  },
];

//**********NOTE: the following start/end dates need to be same as tradeShow.jsx
// const tradeShowDetails = [
//   {
//     name: "Taipei AMPA 2024",
//     start: "2024/04/17",
//     end: "2024/04/20",
//   },
//   {
//     name: "Automechanika Frankfurt 2024",
//     start: "2024/09/10",
//     end: "2024/09/14",
//   },
// ];

const redirectURLs = [
  {
    old: "/index-1.htm",
    new: "/",
  },
  {
    old: "/app_com.htm",
    new: "/",
  },
  {
    old: "/da_m_sine_wave.htm",
    new: "/product/sp-series",
  },
  {
    old: "/da_m_sine_wave_hp.htm",
    new: "/product/hp-series",
  },
  {
    old: "/pr_m_sp5000.htm",
    new: "/product/sp-series/sp-5000",
  },
  {
    old: "/pr_da_sp2004_1500c.htm",
    new: "/product/sp-c-series/sp-1500c",
  },
  {
    old: "/sp-600.htm",
    new: "/product/sp-series/sp-600",
  },
  {
    old: "/product/es-series/hp-600usb-eup",
    new: "/product/es-series/es-600usb",
  },
  {
    old: "/product/sn-series/sn-1000bt",
    new: "/product/sn-bt-series/sn-1000bt",
  },
  {
    old: "/product/sn-series/sn-1500bt",
    new: "/product/sn-bt-series/sn-1500bt",
  },
  {
    old: "/product/sn-series/sn-2000bt",
    new: "/product/sn-bt-series/sn-2000bt",
  },
  {
    old: "/product/sn-series/sn-2500bt",
    new: "/product/sn-bt-series/sn-2500bt",
  },
  {
    old: "/product/sn-series/sn-3000bt",
    new: "/product/sn-bt-series/sn-3000bt",
  },
  {
    old: "/tradeshow",
    new: "/news",
  },
];

class LCAppBar extends React.Component {
  constructor(props) {
    super(props);
    this.cart = {};
    let cartString = localStorage.getItem("inquiryCart");
    if (cartString !== null) {
      this.cart = JSON.parse(cartString);
    }
    this.state = {
      value: false,
      open: false,
      tradeshows: [],
      cartNum: Object.keys(this.cart).length,
      isSnackbarOpen: false,
      dialogOpen: false,
      searchTerm: "",
    };
  }

  componentDidMount() {
    this.fetchNewsData();
  }

  fetchNewsData = async () => {
    try {
      const response = await fetch("/data/news.json");
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const jsonData = await response.json();
      this.setState({ tradeshows: jsonData.tradeshows });
    } catch (error) {
      console.error("Error fetching news data:", error);
    }
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleChange = (event, value) => {
    this.setState({ value: value });
  };

  handleSnackbarClose = () => {
    this.setState({ isSnackbarOpen: false });
  };

  cartCallback = (item) => {
    if (item.moq === 0) {
      delete this.cart[item.name];
    } else {
      this.cart[item.name] = {
        moq: item.moq,
        img: item.img,
        url: item.url,
        spec: item.spec,
      };
      if (item.isNewAdd) {
        this.setState({ isSnackbarOpen: true });
      }
    }
    this.setState({ cartNum: Object.keys(this.cart).length });
    // console.log(JSON.stringify(this.cart));
    localStorage.setItem("inquiryCart", JSON.stringify(this.cart));
  };

  handleDialogClickOpen = () => {
    this.setState({ dialogOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  };

  handleDialogTextFieldChange = (event) => {
    this.setState({ searchTerm: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const { open, tradeshows } = this.state;
    let tradeshowLiveNum = 0;
    let today = new Date();
    let thisYear = today.getFullYear();
    for (var i = 0; i < tradeshows.length; i++) {
      var tradeShowDetail = tradeshows[i];
      if (
        new Date(tradeShowDetail.postDate).getFullYear() === thisYear &&
        today >= Date.parse(tradeShowDetail.start) &&
        today <= Date.parse(tradeShowDetail.end)
      ) {
        tradeshowLiveNum++;
      }
    }
    return (
      <MuiThemeProvider theme={theme}>
        <Router>
          <ScrollToTop>
            <div className={classes.root}>
              <AppBar position="fixed">
                <Toolbar>
                  <IconButton
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="Menu"
                    to="/"
                    component={Link}
                    onClick={this.handleChange}
                  >
                    <Avatar
                      src={logo}
                      alt="LinkChamp Logo"
                      imgProps={{ title: "LinkChamp Logo", loading: "lazy" }}
                      className={classes.bigAvatar}
                    />
                  </IconButton>
                  <Typography
                    variant="h6"
                    color="inherit"
                    className={classes.grow}
                  >
                    <Link
                      to="/"
                      style={{ color: "inherit", textDecoration: "inherit" }}
                    >
                      LINKCHAMP
                    </Link>
                  </Typography>
                  <Hidden mdUp>
                    <IconButton
                      color="inherit"
                      aria-label="Open drawer"
                      onClick={this.handleDrawerOpen}
                      className={classNames(
                        classes.drawerButton,
                        open && classes.hide
                      )}
                    >
                      {this.state.cartNum > 0 || tradeshowLiveNum > 0 ? (
                        <Badge color="secondary" variant="dot">
                          <MenuIcon />
                        </Badge>
                      ) : (
                        <MenuIcon />
                      )}
                    </IconButton>
                  </Hidden>
                  <Hidden smDown>
                    <Tabs
                      value={this.state.value}
                      indicatorColor="secondary"
                      textColor="secondary"
                      onChange={this.handleChange}
                    >
                      {pages.map((page) => (
                        <Tab
                          key={page.label}
                          label={
                            page.label.includes("INQUIRY") ? (
                              <Badge
                                color="secondary"
                                badgeContent={this.state.cartNum}
                              >
                                {page.label}
                              </Badge>
                            ) : page.label.includes("NEWS") &&
                              tradeshowLiveNum > 0 ? (
                              <Badge
                                color="secondary"
                                badgeContent={tradeshowLiveNum}
                              >
                                {page.label}
                              </Badge>
                            ) : (
                              <>{page.label}</>
                            )
                          }
                          to={page.link}
                          component={Link}
                          className={classes.tab}
                        />
                      ))}
                      <IconButton
                        color="inherit"
                        onClick={this.handleDialogClickOpen}
                      >
                        <SearchIcon />
                      </IconButton>
                    </Tabs>
                  </Hidden>
                </Toolbar>
              </AppBar>
              <Dialog
                open={this.state.dialogOpen}
                onClose={this.handleDialogClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">Search</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Please enter any keywords (product, model, ...etc) you would
                    like to search on our website
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Keywords"
                    type="product"
                    fullWidth
                    onChange={this.handleDialogTextFieldChange}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleDialogClose} color="secondary">
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      window.open(
                        "//" +
                          "google.com/search?q=site:dc-ac.com " +
                          this.state.searchTerm,
                        "_blank"
                      );
                      this.handleDialogClose();
                    }}
                    color="secondary"
                  >
                    Search
                  </Button>
                </DialogActions>
              </Dialog>
              <Drawer
                className={classes.drawer}
                anchor="top"
                open={open}
                classes={{
                  paper: classes.drawerPaper,
                }}
                role="button"
                onClick={this.handleDrawerClose}
                onKeyDown={this.handleDrawerClose}
              >
                <div className={classes.drawerHeader}>
                  <IconButton
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="Menu"
                    to="/"
                    component={Link}
                    onClick={this.handleChange}
                  >
                    <Avatar
                      src={logo}
                      alt="LinkChamp Logo"
                      imgProps={{ title: "LinkChamp Logo", loading: "lazy" }}
                      className={classes.bigAvatar}
                    />
                  </IconButton>
                  <Typography
                    variant="h6"
                    color="inherit"
                    className={classes.grow}
                  >
                    LINKCHAMP
                  </Typography>
                  <IconButton
                    onClick={this.handleDrawerClose}
                    className={classes.drawerButton}
                  >
                    {theme.direction === "ltr" ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </IconButton>
                </div>
                <Divider />
                <List>
                  {pages.map((page) => (
                    <ListItem
                      button
                      key={page.label}
                      to={page.link}
                      component={Link}
                    >
                      {page.label.includes("INQUIRY") ? (
                        <Badge
                          color="secondary"
                          badgeContent={this.state.cartNum}
                        >
                          <ListItemText primary={page.label} />
                        </Badge>
                      ) : page.label.includes("TRADE SHOW") &&
                        tradeshowLiveNum > 0 ? (
                        <Badge
                          color="secondary"
                          badgeContent={tradeshowLiveNum}
                        >
                          <ListItemText primary={page.label} />
                        </Badge>
                      ) : (
                        <ListItemText primary={page.label} />
                      )}
                    </ListItem>
                  ))}
                </List>
              </Drawer>
              <Snackbar
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                open={this.state.isSnackbarOpen}
                autoHideDuration={6000}
                onClose={this.handleSnackbarClose}
                ContentProps={{
                  "aria-describedby": "message-id",
                }}
                message={<span id="message-id">Added to Inquiry Cart</span>}
                action={[
                  <Button
                    key="undo"
                    color="secondary"
                    size="small"
                    component={Link}
                    to={"/inquiry"}
                    button="true"
                  >
                    View Cart
                  </Button>,
                  <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={this.handleSnackbarClose}
                  >
                    <CloseIcon />
                  </IconButton>,
                ]}
              />
            </div>
            {/* NOTE: Difference between passing component to Route as prop and wrapping component in render function:
                https://stackoverflow.com/questions/59256109/difference-between-passing-component-to-route-as-prop-and-wrapping-component-in */}
            <Switch>
              {/* Redirect old URLS to new */}
              {redirectURLs.map((url) => (
                <Redirect
                  key={url.old}
                  from={url.old}
                  to={url.new}
                  render={(props) => (
                    <Index
                      {...props}
                      cartCallback={this.cartCallback}
                      cart={this.cart}
                    />
                  )}
                />
              ))}
              <Route
                path="/"
                exact
                render={(props) => (
                  <Index
                    {...props}
                    cartCallback={this.cartCallback}
                    cart={this.cart}
                  />
                )}
              />
              <Route
                path="/company/"
                render={(props) => <Company {...props} />}
              />
              <Route
                path="/product/"
                render={(props) => (
                  <Product
                    {...props}
                    cartCallback={this.cartCallback}
                    cart={this.cart}
                  />
                )}
              />
              <Route
                path="/news/"
                render={(props) => <TradeShow {...props} />}
              />
              {/* <Redirect from="/inquiry/" to="/from.htm" component={Inquiry} /> */}
              <Route
                path="/inquiry/"
                render={(props) => (
                  <Inquiry
                    {...props}
                    cartCallback={this.cartCallback}
                    cart={this.cart}
                  />
                )}
              />
              <Route
                path="/contact/"
                render={(props) => <Contact {...props} />}
              />
              <Route
                path="/privacypolicy/"
                render={(props) => <PrivacyPolicy {...props} />}
              />
              <Route render={(props) => <NotFound {...props} />} />
            </Switch>
          </ScrollToTop>
        </Router>
      </MuiThemeProvider>
    );
  }
}

LCAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(theme)(withStyles(styles)(LCAppBar));
